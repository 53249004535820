import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {createAction} from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const BaseUrl = 'https://prod.machtech.site/WowEffect/adminPanel/api'
const token = localStorage.getItem('token')
const lang = Cookies.get("i18next") || "en";

export const getShopStandardItems = createAsyncThunk('newActions/getShopStandardItems', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/shop?page=${payload}`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getShopFilteredItems = createAsyncThunk('newActions/getShopFilteredItems', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/productFilter?page=${payload.page}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const clearFilter = createAction('newActions/clearFilter')

export const getCustomPageData = createAsyncThunk('newActions/getCustomPageData', async (thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/custom-production`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const addToFavoritesAction = createAsyncThunk('newActions/addToFavorites', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/addToFavorites`, {
            user_id: payload.user_id,
            product_id: payload.product_id
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const registerBeforeCheckout = createAsyncThunk('newActions/registerBeforeCheckout', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/registerBeforeCheckout?lang=${lang}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getPolicy = createAsyncThunk('newActions/getPolicy', async (thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/policy`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getCarers = createAsyncThunk('newActions/getCarers', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/careers?page=${payload.page}`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getSingleCarer = createAsyncThunk('newActions/getSingleCarer', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/career/${payload.id}`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getOrders = createAsyncThunk('newActions/getOrders', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/get-user-orders`, {
            headers: {
                Authorization: `Bearer ${token}`
            }

        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const addBasket = createAsyncThunk('newActions/addBasket', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/addToCart`, payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getInteriorPage = createAsyncThunk('newActions/getInteriorPage', async (thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/interior-design`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getSingleProduct = createAsyncThunk('newActions/getSingleProduct', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/product/${payload.id}`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const postContactUs = createAsyncThunk('newActions/postContactUs', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/contactUsFormSubmit`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getPhone = createAsyncThunk('newActions/getPhone', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/getPhone`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getEmail = createAsyncThunk('newActions/getEmail', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/getEmail`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const getMainInfo = createAsyncThunk('newActions/getMainInfo', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/mainInfo`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const menuToogle = createAction('newActions/menuToogle')

export const postSubscribe = createAsyncThunk('newActions/postSubscribe', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/subscribe`, payload)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const postUserUpdate = createAsyncThunk('newActions/postUserUpdate', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/updateUser/${payload.id}`, payload, {
            headers: {
                Authorization: payload.token,
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getTranslations = createAsyncThunk('newActions/getTranslations', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/getTranslations`)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const getUserAddresses = createAsyncThunk('newActions/getUserAddresses', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/getUserAddresses/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const postAddAddress = createAsyncThunk('newActions/postAddAddress', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/addUserAddress/${payload.id}`, payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const deleteAddress = createAsyncThunk('newActions/deleteAddress', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.delete(`${BaseUrl}/deleteUserAddress/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const updateAddress = createAsyncThunk('newActions/updateAddress', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/updateUserAddress/${payload.id}`, payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getUserSingleAddress = createAsyncThunk('newActions/getUserSingleAddress', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/editUserAddress/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const changeDefaultAddress = createAsyncThunk('newActions/changeDefaultAddress', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/setAddressDefault/${payload.id}`, {user_id: payload.user_id}, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const localBasketAdd = createAction('newActions/localBasketAdd')

export const getUserBasket = createAsyncThunk('newActions/getUserBasket', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/cart/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const selectedItemsAdd = createAction('newActions/selectedItems')

export const selectedItemAll = createAction('newActions/selectedItemAll')

export const productCartIncrement = createAsyncThunk('newActions/productCartIncrement', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/incrementProductCount`, payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const productCartDecrement = createAsyncThunk('newActions/productCartDecrement', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/decrementProductCount`, payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const deleteFromCart = createAsyncThunk('newActions/deleteFromCart', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/deleteFromCart`, payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getTotalPrice = createAction('newActions/getTotalPrice')

export const thanksMessage = createAsyncThunk('newActions/thanksMessage', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/${payload.lang}/thanks/`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const postStoreRequirements = createAsyncThunk('newActions/postStoreRequirements', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/storeRequirements`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const openFavoritesAction = createAction('newActions/openFavorites')

export const getFavorites = createAsyncThunk('newActions/getFavorites', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.get(`${BaseUrl}/favorites/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            }
        })
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const deleteFromLocalBasket = createAction('newActions/deleteFromLocalBasket')

export const localBasketIncrement = createAction('newActions/localBasketIncrement')
export const localBasketDecrement = createAction('newActions/localBasketDecrement')

export const localAddFavorites = createAction('newActions/localAddFavorites')

export const loginModalInfo = createAction('newActions/loginModalInfo')

export const orderCall = createAsyncThunk('newActions/orderCall', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/callOrder`, payload)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const googleAuth = createAsyncThunk('newActions/googleAuth', async (payload, thunkAPI) => {
    try {
        // const width = 600;
        // const height = 600;
        // const left = (window.innerWidth - width) / 2;
        // const top = (window.innerHeight - height) / 2;
        window.location.replace(`${BaseUrl}/auth/google/redirect`)
        // window.open('https://api.woweffect.am/api/Armine/WowEffect/api/auth/google/redirect' , '_blank' , `width=${width},height=${height},left=${left},top=${top}`)
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const faceAuth = createAsyncThunk('newActions/googleAuth', async (payload, thunkAPI) => {
    try {
        window.location.replace(`${BaseUrl}/auth/facebook/redirect`)
        // const data = await axios.get('https://api.woweffect.am/api/Armine/WowEffect/api/auth/facebook/redirect')
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})

export const cheCkError = createAction('newActions/cheCkError')
export const googleLoginRequest = createAsyncThunk('newActions/orderCall', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/auth/google/callback`, payload)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const faceBookLoginRequest = createAsyncThunk('newActions/orderCall', async (payload, thunkAPI) => {
    try {
        const {data} = await axios.post(`${BaseUrl}/auth/facebook/callback`, payload)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export const getGalleryPage = createAsyncThunk('newActions/getGalleryPage' , async (payload , thunkAPI)=>{
    try {
        const {data} = await axios.get(`${BaseUrl}/galleria?page=${payload.page}`, payload)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
})
export  const themeChange = createAction('newActions/themeChange')
