import React, {Fragment, useEffect, useRef, useState} from 'react';
import './style.css'
import {useTranslation} from "react-i18next";
import Footer from "../../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import ARROW_LEFT from "../../assets/icons/arrow-left-white.svg";
import ARROW_RIGHT from "../../assets/icons/arrow-right-white.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import {STORAGE_URL} from "../../services/apiService";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import Carousel from "nuka-carousel";
import {getGalleryPage} from "../../redux/actions/newActions/newActions";
import Cookies from "js-cookie";
import {useSearchParams} from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import arrow_left from "../../assets/icons/arrow-left-white.svg";
import arrow_right from "../../assets/icons/arrow-right-white.svg";
import Img from "../../components/Img";
import ReactPlayer from 'react-player'

const image_extensions = ['jpeg', 'png', 'jpg', 'gif', 'svg'];
const video_extensions = ['flv', 'mp4', 'avi', 'mpeg', 'quicktime']

function Gallery() {
    const dispatch = useDispatch()
    const {width} = useWindowSize()
    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get('page') || 1
    const {t} = useTranslation()
    const handleSetPage = () => {
        const newPage = +page + 1
        setSearchParams({page: newPage})
    }
    const galleryTotalPages = useSelector(state => state.newReducer.galleryPageTotalPages)
    const galleryPage = useSelector(state => state.newReducer.galleryPage)
    const lang = Cookies.get("i18next") || "en";
    const [hovered, setHovered] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const theme = useSelector(state => state.newReducer.themeChange)
    const [selectedItem, setSelectedItem] = useState(null)
    const [openModal, setOpenModal] = useState(null)
    const [selectedVideoId, setSelectedVideoId] = useState(null)
    console.log(galleryPage)
    const handlePlayVideo = (id) => {
        const video = document.getElementById(`video_${id}`)
        if (video.paused) {
            video.play();
            setSelectedVideoId(id)
        } else {
            setSelectedVideoId(null)
            video.pause();
        }
    }

    useEffect(() => {
        dispatch(getGalleryPage({page}))
    }, [page]);
    return (
        <>
            {openModal && (
                <Modal
                    isSlider={true}
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    galleryModal={true}
                >
                    {selectedItem?.images?.length > 0 ?
                        <div>
                            <Carousel
                                renderCenterLeftControls={({previousSlide, currentSlide}) => (
                                    currentSlide === 0 ? null : (
                                        <button
                                            style={{
                                                cursor: currentSlide === 0 ? 'not-allowed' : '',
                                                width: 40,
                                                height: 40,
                                                background: "#0F0F0F",
                                                display: 'flex',
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                borderRadius: '50%',
                                                border: 'none',
                                                outline: 'none',
                                                marginLeft: '10px'
                                            }}
                                            disabled={currentSlide === 0} onClick={previousSlide}>
                                            <img src={arrow_left} alt='arrow'/>
                                        </button>
                                    )

                                )}
                                renderCenterRightControls={(props) => (
                                    selectedItem?.images?.length - 1 <= props.currentSlide ? null : (<button
                                        style={{
                                            width: 40,
                                            height: 40,
                                            background: "#0F0F0F",
                                            display: 'flex',
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            border: 'none',
                                            outline: 'none',
                                            marginRight: '10px'
                                        }}
                                        onClick={props.nextSlide}>
                                        <img src={arrow_right} alt='arrow'/>
                                    </button>)

                                )}
                                animation={'zoom'}
                                slidesToShow={1}
                                style={{
                                    width: width > 900 ? 600 : ''
                                }}
                            >
                                {[...selectedItem.images].sort((a, b) => b.is_main - a.is_main).map((e, index) => {
                                    const extension = e.path.split('.').pop().toLowerCase();
                                    if (image_extensions.includes(extension)) {
                                        return (
                                            <img
                                                style={{
                                                    height: index === 4 ? '100%' : '',
                                                }}
                                                key={e.id}
                                                className='gallery-item-image'
                                                src={`${STORAGE_URL}/${e.path}`}
                                                alt={`Image ${e.path}`}
                                            />
                                        );
                                    } else {
                                        return (
                                            <ReactPlayer
                                                url={e.path}
                                                width='100%'
                                                height='100%'
                                                controls
                                                config={{
                                                    youtube: {
                                                        playerVars: {
                                                            modestbranding: 0,
                                                            controls: 0,
                                                            showinfo: 0
                                                        }
                                                    }
                                                }}
                                            />
                                        );
                                    }
                                })}
                            </Carousel>
                        </div>
                        : null}
                </Modal>
            )}
            <div className='galleryPageContainer container'>
                <div className='galleryPageInfo'>
                    <h2 style={{
                        color: theme === 'ligth' ? 'black' : ''
                    }} className='gallery-title'>{galleryPage[`heading_${lang}`]}</h2>
                    <p style={{
                        color: theme === 'ligth' ? 'black' : ''
                    }} className='pagesTitleDescription'>{removeHtmlTags(galleryPage[`description_${lang}`])}</p>
                </div>
                {width < 800 ? (
                    <Carousel
                        style={{
                            marginTop: 60
                        }}
                        slidesToShow={1.3}
                        renderCenterRightControls={() => null}
                        renderCenterLeftControls={() => null}
                        renderBottomCenterControls={() => null}
                        wrapAround={true}
                    >
                        {galleryPage?.item?.map((item, index) => (
                            <div style={{
                                border: theme === 'ligth' ? '' : '1px solid white'
                            }} key={item.id} className={`gallery-item`}>
                                <Carousel
                                    animation={'zoom'}
                                    slidesToShow={1}
                                    renderCenterRightControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide + 1 !== props.slideCount) {
                                            return (
                                                <button className="gallery-item-slider-btn" onClick={() => {
                                                    setOpenModal(false)
                                                    props.nextSlide()
                                                }}>
                                                    <img src={ARROW_RIGHT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderCenterLeftControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide !== 0) {
                                            return (
                                                <button className="gallery-item-slider-btn"
                                                        onClick={props.previousSlide}>
                                                    <img src={ARROW_LEFT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderBottomCenterControls={() => null}
                                    wrapAround={false}
                                >
                                    {[...item.images].sort((a, b) => b.is_main - a.is_main).map((e, index) => {
                                        const extension = e.path.split('.').pop().toLowerCase();
                                        if (image_extensions.includes(extension)) {
                                            return (
                                                <img
                                                    onClick={() => {
                                                        setOpenModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                    style={{
                                                        height: index === 4 ? '100%' : ''
                                                    }}
                                                    key={e.id}
                                                    className='gallery-item-image'
                                                    src={`${STORAGE_URL}/${e.path}`}
                                                    alt={`Image ${e.path}`}
                                                />
                                            );
                                        } else {
                                            return (
                                                <ReactPlayer
                                                    url={e.path}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    config={{
                                                        youtube: {
                                                            playerVars: {
                                                                modestbranding: 0,
                                                                controls: 0,
                                                                showinfo: 0
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </Carousel>
                                <div style={{
                                    bottom: hovered && selectedIndex === index ? '-100%' : ''
                                }} className='portfolioGalleryInfo'>
                                    <div className="galleryBoxTitleWrapper">
                                        <p className='galleryBoxTitle'>{removeHtmlTags(item[`title_${lang}`])}</p>
                                        <p className='galleryBoxDesc'>
                                            {removeHtmlTags(item[`description_${lang}`])}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </Carousel>) : <>
                    <div className="angry-grid">
                        {galleryPage.item?.slice(0, 5).map((item, index) => (
                            <div
                                style={{
                                    border: theme === 'ligth' ? '' : '1px solid white',
                                    cursor: 'pointer'
                                }}
                                key={item.id} className={`gallery-item item-${index}`}>
                                <Carousel
                                    animation={'zoom'}
                                    slidesToShow={1}
                                    renderCenterRightControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide + 1 !== props.slideCount) {
                                            return (
                                                <button className="gallery-item-slider-btn" onClick={() => {
                                                    setOpenModal(false)
                                                    props.nextSlide()
                                                }}>
                                                    <img src={ARROW_RIGHT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderCenterLeftControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide !== 0) {
                                            return (
                                                <button className="gallery-item-slider-btn"
                                                        onClick={props.previousSlide}>
                                                    <img src={ARROW_LEFT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderBottomCenterControls={() => null}
                                    wrapAround={false}
                                >
                                    {[...item.images].sort((a, b) => b.is_main - a.is_main).map((e, index) => {
                                        const extension = e.path.split('.').pop().toLowerCase();
                                        if (image_extensions.includes(extension)) {
                                            return (
                                                <img
                                                    onClick={() => {
                                                        setOpenModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                    style={{
                                                        height: index === 4 ? '100%' : ''
                                                    }}
                                                    key={e.id}
                                                    className='gallery-item-image'
                                                    src={`${STORAGE_URL}/${e.path}`}
                                                    alt={`Image ${e.path}`}
                                                />
                                            );
                                        } else {
                                            return (
                                                    <ReactPlayer
                                                        url={e.path}
                                                        width='100%'
                                                        height='100%'
                                                        controls
                                                        config={{
                                                            youtube: {
                                                                playerVars: {
                                                                    modestbranding: 0,
                                                                    controls: 0,
                                                                    showinfo: 0
                                                                }
                                                            }
                                                        }}
                                                    />
                                            );
                                        }
                                    })}
                                </Carousel>
                                <div style={{
                                    bottom: hovered && selectedIndex === index ? '-100%' : ''
                                }} className='portfolioGalleryInfo'>
                                    <div className="galleryBoxTitleWrapper">
                                        <p className='galleryBoxTitle'>{removeHtmlTags(item[`title_${lang}`])}</p>
                                        <p className='galleryBoxDesc'>
                                            {removeHtmlTags(item[`description_${lang}`])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="large">
                        {galleryPage.item?.slice(5, 7).map((item, index) => (
                            <div style={{
                                border: theme === 'ligth' ? '' : '1px solid white',
                                cursor: 'pointer'
                            }}
                                 key={item.id} className={'gallery-item large-1'}>
                                <Carousel
                                    animation={'zoom'}
                                    slidesToShow={1}
                                    renderCenterRightControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide + 1 !== props.slideCount) {
                                            return (
                                                <button className="gallery-item-slider-btn" onClick={() => {
                                                    setOpenModal(false)
                                                    props.nextSlide()
                                                }}>
                                                    <img src={ARROW_RIGHT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderCenterLeftControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide !== 0) {
                                            return (
                                                <button className="gallery-item-slider-btn"
                                                        onClick={props.previousSlide}>
                                                    <img src={ARROW_LEFT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderBottomCenterControls={() => null}
                                    wrapAround={false}
                                >
                                    {[...item.images].sort((a, b) => b.is_main - a.is_main).map((e, index) => {
                                        const extension = e.path.split('.').pop().toLowerCase();
                                        if (image_extensions.includes(extension)) {
                                            return (
                                                <img
                                                    onClick={() => {
                                                        setOpenModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                    style={{
                                                        height: index === 4 ? '100%' : ''
                                                    }}
                                                    key={e.id}
                                                    className='gallery-item-image'
                                                    src={`${STORAGE_URL}/${e.path}`}
                                                    alt={`Image ${e.path}`}
                                                />
                                            );
                                        } else {
                                            return (
                                                <ReactPlayer
                                                    url={e.path}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    config={{
                                                        youtube: {
                                                            playerVars: {
                                                                modestbranding: 0,
                                                                controls: 0,
                                                                showinfo: 0
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </Carousel>
                                <div style={{
                                    bottom: hovered && selectedIndex === index ? '-100%' : ''
                                }} className='portfolioGalleryInfo'>
                                    <div className="galleryBoxTitleWrapper">
                                        <p className='galleryBoxTitle'>{removeHtmlTags(item[`title_${lang}`])}</p>
                                        <p className='galleryBoxDesc'>
                                            {removeHtmlTags(item[`description_${lang}`])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="large">
                        {galleryPage.item?.slice(7, 10).map((item, index) => (
                            <div style={{
                                border: theme === 'ligth' ? '' : '1px solid white',
                                cursor: 'pointer'
                            }}
                                 key={item.id} className={'gallery-item large-1'}>
                                <Carousel
                                    animation={'zoom'}
                                    slidesToShow={1}
                                    renderCenterRightControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide + 1 !== props.slideCount) {
                                            return (
                                                <button className="gallery-item-slider-btn" onClick={() => {
                                                    setOpenModal(false)
                                                    props.nextSlide()
                                                }}>
                                                    <img src={ARROW_RIGHT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderCenterLeftControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide !== 0) {
                                            return (
                                                <button className="gallery-item-slider-btn"
                                                        onClick={props.previousSlide}>
                                                    <img src={ARROW_LEFT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderBottomCenterControls={() => null}
                                    wrapAround={false}
                                >
                                    {[...item.images].sort((a, b) => b.is_main - a.is_main).map((e, index) => {
                                        const extension = e.path.split('.').pop().toLowerCase();
                                        if (image_extensions.includes(extension)) {
                                            return (
                                                <img
                                                    onClick={() => {
                                                        setOpenModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                    style={{
                                                        height: index === 4 ? '100%' : ''
                                                    }}
                                                    key={e.id}
                                                    className='gallery-item-image'
                                                    src={`${STORAGE_URL}/${e.path}`}
                                                    alt={`Image ${e.path}`}
                                                />
                                            );
                                        } else {
                                            return (
                                                <ReactPlayer
                                                    url={e.path}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    config={{
                                                        youtube: {
                                                            playerVars: {
                                                                modestbranding: 0,
                                                                controls: 0,
                                                                showinfo: 0
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </Carousel>
                                <div style={{
                                    bottom: hovered && selectedIndex === index ? '-100%' : ''
                                }} className='portfolioGalleryInfo'>
                                    <div className="galleryBoxTitleWrapper">
                                        <p className='galleryBoxTitle'>{removeHtmlTags(item[`title_${lang}`])}</p>
                                        <p className='galleryBoxDesc'>
                                            {removeHtmlTags(item[`description_${lang}`])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='big-block'>
                        {galleryPage.item?.slice(10, 11).map((item, index) => (
                            <div style={{
                                border: theme === 'ligth' ? '' : '1px solid white',
                                cursor: 'pointer'
                            }}
                                 key={item.id} className={'gallery-item'}>
                                <Carousel
                                    animation={'zoom'}
                                    slidesToShow={1}
                                    renderCenterRightControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide + 1 !== props.slideCount) {
                                            return (
                                                <button className="gallery-item-slider-btn" onClick={() => {
                                                    setOpenModal(false)
                                                    props.nextSlide()
                                                }}>
                                                    <img src={ARROW_RIGHT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderCenterLeftControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide !== 0) {
                                            return (
                                                <button className="gallery-item-slider-btn"
                                                        onClick={props.previousSlide}>
                                                    <img src={ARROW_LEFT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderBottomCenterControls={() => null}
                                    wrapAround={false}
                                >
                                    {[...item.images].sort((a, b) => b.is_main - a.is_main).map((e, index) => {
                                        const extension = e.path.split('.').pop().toLowerCase();
                                        if (image_extensions.includes(extension)) {
                                            return (
                                                <img
                                                    onClick={() => {
                                                        setOpenModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                    style={{
                                                        height: index === 4 ? '100%' : ''
                                                    }}
                                                    key={e.id}
                                                    className='gallery-item-image'
                                                    src={`${STORAGE_URL}/${e.path}`}
                                                    alt={`Image ${e.path}`}
                                                />
                                            );
                                        } else {
                                            return (
                                                <ReactPlayer
                                                    url={e.path}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    config={{
                                                        youtube: {
                                                            playerVars: {
                                                                modestbranding: 0,
                                                                controls: 0,
                                                                showinfo: 0
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </Carousel>
                                <div style={{
                                    bottom: hovered && selectedIndex === index ? '-100%' : ''
                                }} className='portfolioGalleryInfo'>
                                    <div className="galleryBoxTitleWrapper">
                                        <p className='galleryBoxTitle'>{removeHtmlTags(item[`title_${lang}`])}</p>
                                        <p className='galleryBoxDesc'>
                                            {removeHtmlTags(item[`description_${lang}`])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{
                        flexWrap: 'wrap'
                    }} className="large">
                        {galleryPage.item?.slice(11).map((item, index) => (
                            <div style={{
                                border: theme === 'ligth' ? '' : '1px solid white',
                                cursor: 'pointer',
                                width: '31.2%'
                            }}
                                 key={item.id} className={'gallery-item large-1'}>
                                <Carousel
                                    animation={'zoom'}
                                    slidesToShow={1}
                                    renderCenterRightControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide + 1 !== props.slideCount) {
                                            return (
                                                <button className="gallery-item-slider-btn" onClick={() => {
                                                    setOpenModal(false)
                                                    props.nextSlide()
                                                }}>
                                                    <img src={ARROW_RIGHT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderCenterLeftControls={(props) => {
                                        if (props.slideCount > 1 && props.currentSlide !== 0) {
                                            return (
                                                <button className="gallery-item-slider-btn"
                                                        onClick={props.previousSlide}>
                                                    <img src={ARROW_LEFT} alt='Arrow'/>
                                                </button>
                                            )
                                        }
                                        return null
                                    }}
                                    renderBottomCenterControls={() => null}
                                    wrapAround={false}
                                >
                                    {item.images.map((e) => {
                                        const extension = e.path.split('.').pop().toLowerCase();
                                        if (image_extensions.includes(extension)) {
                                            return (
                                                <img
                                                    onClick={() => {
                                                        setOpenModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                    key={e.id}
                                                    className='gallery-item-image'
                                                    src={`${STORAGE_URL}/${e.path}`}
                                                    alt={`Image ${e.path}`}
                                                />
                                            );
                                        } else if (video_extensions.includes(extension)) {
                                            return (
                                                <>
                                                    <video
                                                        id={`video_${e.id}`}
                                                        onMouseEnter={() => {
                                                            setHovered(true)
                                                            setSelectedIndex(index)
                                                        }}
                                                        style={{height: '100%'}}
                                                        onMouseLeave={() => setHovered(false)}
                                                        key={e.id}
                                                        className='gallery-item-video'
                                                        controls={hovered && selectedIndex === index}
                                                        autoPlay={false}
                                                        muted={true}
                                                    >
                                                        <source src={`${STORAGE_URL}/${e.path}`}
                                                                type={`video/${extension}`}/>
                                                        Your browser does not support the video tag.
                                                    </video>
                                                    <div
                                                        onMouseLeave={() => setHovered(false)}
                                                        onMouseEnter={() => {
                                                            setHovered(true)
                                                            setSelectedIndex(index)
                                                        }} className='video-controller-btn'>
                                                        <div style={{
                                                            display: !hovered && selectedVideoId === e.id ? 'none' : ''
                                                        }} onClick={() => handlePlayVideo(e.id)}
                                                             className={`botón ${selectedVideoId === e.id ? 'active' : ''}`}>
                                                            <div className="fondo" x="0" y="0" width="200"
                                                                 height="200"></div>
                                                            <div className="icono" width="200" height="200">
                                                                <div className="parte izquierda" x="0" y="0" width="200"
                                                                     height="200" fill="#fff"></div>
                                                                <div className="parte derecha" x="0" y="0" width="200"
                                                                     height="200" fill="#fff"></div>
                                                            </div>
                                                            <div className="puntero"></div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    })}
                                </Carousel>
                                <div style={{
                                    bottom: hovered && selectedIndex === index ? '-100%' : ''
                                }} className='portfolioGalleryInfo'>
                                    <div className="galleryBoxTitleWrapper">
                                        <p className='galleryBoxTitle'>{removeHtmlTags(item[`title_${lang}`])}</p>
                                        <p className='galleryBoxDesc'>
                                            {removeHtmlTags(item[`description_${lang}`])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>}
                {galleryTotalPages > page ? (
                    <div onClick={handleSetPage} style={{
                        padding: width > 800 ? '0 100px' : ''
                    }}>
                        <button type="button"
                                className="ant-btn css-dev-only-do-not-override-nllxry ant-btn-default seeMoreBtn "
                                style={{
                                    marginTop: '48px',
                                    width: '100%',
                                    minHeight: '48px',
                                    borderColor: theme === 'ligth' ? '#020202' : ''
                                }}>
                    <span style={{
                        color: theme === 'ligth' ? 'black' : ''
                    }}>{t('See More')}</span></button>
                    </div>
                ) : null}
            </div>
            <Footer/>
        </>
    );
}

export default Gallery;
