import INSTAGRAM from "../../assets/social-medias/instagram-white.svg";
import YOUTUBE from "../../assets/social-medias/youtube-white.svg";
import FACEBOOK from "../../assets/social-medias/facebook-white.svg";
import TWITTER from "../../assets/social-medias/twitter-white.svg";
import TELEPHONE_WHITE from "../../assets/icons/telephone-white.svg";
import TELEPHONE_PINK from "../../assets/icons/telephone-pink.svg";
import {Link} from "react-router-dom";
import {Dropdown, Space} from "antd";
import ARROW_DOWN from "../../assets/icons/arrow-down-white.svg";
import "./style.css";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {getEmail, getMainInfo, getPhone, orderCall, postSubscribe} from "../../redux/actions/newActions/newActions";
import Modal from "../Modal/Modal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Button from "../Button/Button";
import ValidationSchema from "../../utils/ValidationSchema";
import {toast} from "react-toastify";
import PhoneInput from "react-phone-input-2";
import {
    isValidPhoneNumber,
} from 'libphonenumber-js'

export const TopHeader = () => {
    const theme = useSelector(state => state.newReducer.themeChange)

    const {t} = useTranslation();
    const {phoneNumberSchema} = ValidationSchema();
    const ref = useRef(null)
    const langs = [
        {
            id: 1,
            code: "en",
            longName: "ENGLISH",
            label: <Link>en</Link>,
            key: "0",
        },
        {
            id: 2,
            code: "ru",
            longName: "Русский",
            label: <Link>ru</Link>,
            key: "1",
        },
        {
            id: 3,
            code: "am",
            longName: "Հայերեն",
            label: <Link>am</Link>,
            key: "3",
        },
    ];
    const dispatch = useDispatch()
    const [countryCode, setCountryCode] = useState('')
    const [openCallModal, setOpenCallModal] = useState(false)
    const phone = useSelector(state => state.newReducer.phone)
    const main_info = useSelector(state => state.newReducer.main_info)
    useEffect(() => {
        dispatch(getPhone())
        dispatch(getMainInfo())
    }, []);
    const handleLanguageChange = language => {
        i18next.changeLanguage(language).then(() => {
            const currentPath = window.location.pathname;
            const languagePrefix = currentPath.split("/")[1];
            let newPath;
            if (languagePrefix && languagePrefix.length === 2) {
                newPath = currentPath.replace(`/${languagePrefix}`, `/${language}`);
            } else {
                newPath = `/${language}${currentPath}`;
            }
            window.location.href = newPath;
        });
    };

    const items = [
        {
            label: <Link onClick={() => handleLanguageChange("ru")}>ru</Link>,
            code: "ru",
            key: "0",
        },
        {
            label: <Link onClick={() => handleLanguageChange("am")}>am</Link>,
            code: "am",
            key: "1",
        },
        {
            label: <Link onClick={() => handleLanguageChange("en")}>en</Link>,
            code: "en",
            key: "2",
        },
    ];
    const trans = useSelector(state => state.newReducer.trans)
    const lang = Cookies.get("i18next");
    const [phoneNumber, setPhoneNumber] = useState('')
    const [error, setError] = useState('')
    const copyLangs = items.filter(item => item.code !== lang);
    const differentLang = langs.find(
        item1 => !copyLangs.some(item2 => item1.id === item2.id)
    );
    const checkValidNumber = (phone, country = 'AM') => {
        return isValidPhoneNumber(phone, country)
    }

    return (
        <>
            <Modal
                title={trans.length > 0  ? trans.filter((e)=>e.key === 'order_a_call')[0][`value_${lang}`] : t("topHeader.order-call")}
                open={openCallModal}
                onClose={() => {
                    setPhoneNumber('')
                    setError('')
                    setCountryCode('')
                    setOpenCallModal(false)
                }}
            >
                <Formik
                    initialValues={{
                        phone: ""
                    }}
                    onSubmit={async (values, actions) => {
                        actions.setSubmitting(false);
                        if (phoneNumber){
                            const x = checkValidNumber(phoneNumber, countryCode)
                            if (x){
                                setError('')
                                setCountryCode('')
                                setPhoneNumber('')
                                const {payload} = await dispatch(orderCall({phone: phoneNumber}))
                                if (payload.status === "success") {
                                    toast.success(payload[`message_${lang}`])
                                    setOpenCallModal(false)
                                    setPhoneNumber('')
                                    setCountryCode('')
                                }
                                if (payload.status === 'error') {
                                    setError(t("validations.invalid_phone_r"))
                                }
                                actions.resetForm()
                            }else {
                                setError(t("invalidPhone"))
                            }
                        }else {
                            setError(t("validations.invalid_phone_r"))
                        }
                    }}
                    // validationSchema={phoneNumberSchema}
                >
                    {() => (
                        <Form className='footerSubscribe'>
                            <div className='footerItems'>
                                <PhoneInput
                                    ref={ref}
                                    // isValid={(value, country)=>(bool)}
                                    // defaultValue={user?.phone || userInfo.phone}
                                    // value={user?.phone || userInfo.phone}
                                    placeholder={t('placeholder.enter_your_phone_number')}
                                    inputClass={`custom-phone-input ${theme === 'ligth' ? 'ligth' : ''}`}
                                    country={'am'}
                                    inputStyle={{
                                        color: theme === 'ligth' ? '#000 !important' : '',
                                    }}
                                    value={phoneNumber}
                                    onChange={(value, country) => {
                                        setError('')
                                        setCountryCode(country.countryCode?.toUpperCase())
                                        setPhoneNumber(value)
                                    }}
                                />
                                {error && <p style={{
                                    color: '#cc0000'
                                }} className='authError'>{error}</p>}
                                <ErrorMessage
                                    name='phone'
                                    component='span'
                                    className='authError'
                                />
                                <Button
                                    link={false}
                                    btnType='submit'
                                    className='footerSendBtn'
                                    text={t("send")}
                                    style={{
                                        borderRadius: "4px",
                                        background: "var(--main-color-pink)",
                                        color: "var(--secondary-color-white)",
                                        border: "none",
                                        width: "fit-content",
                                    }}
                                />
                            </div>
                        </Form>

                    )}
                </Formik>
            </Modal>
            <div className='topHeaderContainer container'>
                <div className='topHeader1'>
                    <div className='topHeaderSocialMedias'>
                        <a href={main_info.instagram_link} target="_blank" className='topHeaderSocialMediaItem'>
                            <img src={INSTAGRAM} alt='Social Media'/>
                        </a>
                        <a href={main_info.youtube_link} target="_blank" className='topHeaderSocialMediaItem'>
                            <img src={YOUTUBE} alt='Social Media'/>
                        </a>
                        <a href={main_info.facebook_link} target="_blank" className='topHeaderSocialMediaItem'>
                            <img src={FACEBOOK} alt='Social Media'/>
                        </a>
                        <a href={main_info.twitter_link} target="_blank" className='topHeaderSocialMediaItem'>
                            <img src={TWITTER} alt='Social Media'/>
                        </a>
                    </div>
                    <div className='topHeader1Line'></div>
                    <div className='topHeaderTelephone'>
                        <img src={TELEPHONE_WHITE} alt='Telephone'/>
                        <span><a style={{textDecoration: 'none', color: 'white'}}
                                 href={`tel:${phone}`}>{phone}</a></span>
                    </div>
                </div>
                <div className='topHeader2'>
                    {trans.filter((e)=>e.key ==='order_a_call').map((e)=> (
                        <button
                            key={e.key}
                            onClick={() => setOpenCallModal(true)}
                            style={{textDecoration: 'none'}} className='topHeaderOrderCallBtn'>
                            <img src={TELEPHONE_PINK} alt='Telephone'/>
                            <span>{e[`value_${lang}`]}</span>
                        </button>
                    ))}
                    <Dropdown
                        menu={{
                            items: copyLangs,
                        }}
                        trigger={["click"]}>
                        <button
                            onClick={e => e.preventDefault()}
                            className='topHeaderLangsBtn'>
                            <Space>
                                {lang}
                                <img
                                    src={ARROW_DOWN}
                                    alt='Arrow'
                                    className='topHeaderLangBtnImg'
                                />
                            </Space>
                        </button>
                    </Dropdown>
                </div>
            </div>
        </>
    );
};
