import { useEffect } from "react";
import CLOSE from "../../assets/icons/close-white.svg";
import Img from "../Img";
import "./style.css";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useSelector} from "react-redux";

const Modal = ({ open, onClose, children, title,isAuth=false,isFilter=false,isSlider=false,galleryModal=false }) => {
  useEffect(() => {
    document.body.classList.toggle("no-scroll", open);
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);
  const {width} = useWindowSize()
  const theme = useSelector(state => state.newReducer.themeChange)

  return (
    <div style={{
      zIndex:isFilter ? 0 : '',
    }} className={`${open && "overlayOpen"} overlay`} onClick={onClose}>
      <div style={{
        padding: isSlider ? 0 : '',
        alignItems:galleryModal ? 'center' : ''
      }} className='modalWrapper'>
        <div style={{
          background:theme === 'ligth' ? 'white': '',
          border:isSlider ? 'none' : '',
          position:isSlider ? 'relative' : '',
          width:isFilter && width<700 ? '95%' : '',
          minWidth:isFilter && width<700 ? '95%' : '',
        }} className='modal' onClick={e => e.stopPropagation()}>
          <div className='modalHeader'>
            <p style={{
              color:theme === 'ligth' ? 'black' :''
            }} className='modalHeaderTitle'>{title}</p>
            {!isAuth && <button style={{
              position: isSlider ? 'absolute' : '',
              right: width > 900 ? 60 : 30,
              top: 70,
              zIndex: isSlider ? 100000 : 0
            }} className='modalCloseBtn' onClick={onClose}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 13L13 1M1 1L13 13" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </button>}
          </div>
          <div className='modalBody'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
